import React, { useState, useEffect } from "react";
import { gql, useMutation } from "@apollo/client";
import { graphql } from "gatsby";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useTheme } from "@mui/styles";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useI18next, Link } from "gatsby-plugin-react-i18next";
import SelectCountryEn from "../components/lists/select-country-en";
import SelectCountryEs from "../components/lists/select-country-es";
import ArrowBackIosNew from "@mui/icons-material/ArrowBackIosNew";
import {
  professionOptionsEn,
  professionOptionsEs,
} from "../utils/alternate-lang";
import SEO from "../components/seo";
import { getAlternateLang } from "../utils/alternate-lang";
import { buildTherapeuticAreasOptions } from "../utils/therapeutic-areas";
import { gtmPushUserInfo } from "../services/auth";

export const query = graphql`
  query Register($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    therapeuticAreaEn: allStrapiTherapeuticalArea(
      filter: { locale: { eq: "en" } }
    ) {
      edges {
        node {
          Name
          Slug
          color
          AlternateSlug
        }
      }
    }
    therapeuticAreaEs: allStrapiTherapeuticalArea(
      filter: { locale: { eq: "es-PA" } }
    ) {
      edges {
        node {
          Name
          Slug
          color
          AlternateSlug
        }
      }
    }
  }
`;

const Register = ({ data }) => {
  const theme = useTheme();
  const { t, language } = useI18next();

  useEffect(() => {
    window.dataLayer?.push({
      event: "page_info",
      page_language: language,
      page_category: "register",
      page_ta: "",
      page_type: "",
    });
    gtmPushUserInfo();
  }, [language]);

  const REGISTER = gql`
    mutation (
      $userLastName: String!
      $userFirstName: String!
      $username: String!
      $email: String!
      $country: String!
      $password: String!
      $areasOfExpertise: String
      $profession: String!
      $subscribedNewsletter: Boolean!
      $favoriteLanguage: String
    ) {
      register(
        input: {
          userLastName: $userLastName
          userFirstName: $userFirstName
          username: $username
          email: $email
          country: $country
          password: $password
          areasOfExpertise: $areasOfExpertise
          profession: $profession
          subscribedNewsletter: $subscribedNewsletter
          favoriteLanguage: $favoriteLanguage
        }
      ) {
        jwt
        user {
          id
          userLastName
          email
          userFirstName
          country
          areasOfExpertise
          profession
          subscribedNewsletter
          favoriteLanguage
        }
      }
    }
  `;

  const [register] = useMutation(REGISTER);
  const [showThankYouForSigninUpScreen, setShowThankYouForSigninUpScreen] =
    useState(false);
  const handleChangeAreas = (event) => {
    const {
      target: { value },
    } = event;
    const newValues = typeof value === "string" ? value.split(",") : value;
    if (newValues.length > 1 && newValues.includes("other")) {
      setValues({
        ...values,
        areasOfExpertise: newValues.filter((area) => "other" !== area),
      });
    } else {
      setValues({
        ...values,
        areasOfExpertise: newValues,
      });
    }
  };

  const handleSubmit = (event) => {
    if (!values.isDoctor) {
      setShowMessageMustBeDoctor(true);
      return;
    }
    event.preventDefault();
    register({
      variables: {
        favoriteLanguage: language,
        email: values.email,
        password: values.password,
        username: values.email,
        userLastName: values.userLastName,
        userFirstName: values.userFirstName,
        country: values.country,
        areasOfExpertise:
          // We store the EN Slug of the therapeutic area
          JSON.stringify(values.areasOfExpertise),
        profession: values.profession,
        subscribedNewsletter: values.subscribedNewsletter,
      },
    })
      .then((data) => {
        // Handle success.
        setShowThankYouForSigninUpScreen(true);
      })
      .catch((error) => {
        // Handle error.
        console.log(
          "registration : an error occurred:",
          error.graphQLErrors[0]?.extensions.exception.data.message[0]
            .messages[0].message
        );
        if (
          error.graphQLErrors[0]?.extensions.exception.data.message[0]
            .messages[0].message === "Email is already taken." ||
          error.graphQLErrors[0]?.extensions.exception.data.message[0]
            .messages[0].message === "Username already taken"
        )
          setShowMessageEmailAlreadyTaken(true);
        if (
          error.graphQLErrors[0]?.extensions.exception.data.message[0]
            .messages[0].message === "Please provide valid email address."
        )
          setShowMessageInvalidEmail(true);
      });

    return false;
  };

  const [values, setValues] = React.useState({
    password: "",
    userLastName: "",
    userFirstName: "",
    email: "",
    showPassword: false,
    country: "",
    isDoctor: false,
    areasOfExpertise: [],
    profession: "",
    subscribedNewsletter: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleChangeCheckbox = (event) => {
    setValues({ ...values, isDoctor: !values.isDoctor });
  };

  const handleSubscribeNewsletter = (event) => {
    setValues({
      ...values,
      subscribedNewsletter: !values.subscribedNewsletter,
    });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const getRenderAreas = (selected) => {
    if (
      !selected ||
      selected.length === 0 ||
      (selected.length === 1 && "other" === selected[0])
    ) {
      return t("other");
    }
    return therapeuticAreaOptions
      .filter((item) => selected.includes(item.id))
      .map((item) => (language === "en" ? item.nameEn : item.nameEs))
      .join(", ");
  };

  const [therapeuticAreaOptions, setTherapeuticAreaOptions] = useState(
    buildTherapeuticAreasOptions(
      data.therapeuticAreaEn.edges,
      data.therapeuticAreaEs.edges
    )
  );

  const [showMessageMustBeDoctor, setShowMessageMustBeDoctor] = useState(false);
  const [showMessageEmailAlreadyTaken, setShowMessageEmailAlreadyTaken] =
    useState(false);
  const [showMessageInvalidEmail, setShowMessageInvalidEmail] = useState(false);
  const professionOptions =
    language === "en" ? professionOptionsEn : professionOptionsEs;

  const seo = {
    metaTitle: t("nav.register"),
    metaDescription: t("nav.register").toLocaleLowerCase(),
    linkAlternateHref: "/" + getAlternateLang(language) + "/login",
    linkHrefLang: getAlternateLang(language),
    noindex: true,
  };

  return (
    <Box
      sx={{
        [theme.breakpoints.down("xl")]: {
          maxWidth: "100vw",
        },
      }}
    >
      <SEO seo={seo} />
      <Container
        maxWidth="xl"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          p: 2,
          py: 8,
          gap: 8,
        }}
      >
        <Link
          underline="hover"
          to="/"
          style={{
            display: "flex",
            alignItems: "center",
            textDecoration: "none",
          }}
        >
          <ArrowBackIosNew />
          <Typography style={{ color: theme.palette.grey.servier_1 }}>
            {t("backHomePage")}
          </Typography>
        </Link>
        {showThankYouForSigninUpScreen ? (
          <Container
            maxWidth="xl"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              p: 2,
              py: 8,
              gap: 2,
            }}
          >
            <Typography variant="h1" sx={{ mb: "2rem" }}>
              {t("register.thankYouForSigningUp")}
            </Typography>
            <Typography variant="body">
              {t("register.anEmailHasBeenSent")}
            </Typography>
            <Link underline="hover" to={`/login`}>
              <Typography variant="body">
                {t("register.clickToLogin")}
              </Typography>
            </Link>
          </Container>
        ) : (
          <Container
            maxWidth="xl"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              p: 2,
              py: 8,
              gap: 8,
            }}
          >
            <Typography variant="h1">{t("register.signUp")}</Typography>
            <FormControl sx={{ width: "35ch" }} variant="standard">
              <InputLabel htmlFor="email">{t("register.firstName")}</InputLabel>
              <Input
                id="userFirstName"
                value={values.userFirstName}
                onChange={handleChange("userFirstName")}
              />
            </FormControl>
            <FormControl sx={{ width: "35ch" }} variant="standard">
              <InputLabel htmlFor="email">{t("register.lastName")}</InputLabel>
              <Input
                id="userLastName"
                value={values.userLastName}
                onChange={handleChange("userLastName")}
              />
            </FormControl>
            <FormControl sx={{ width: "35ch" }} variant="standard">
              <InputLabel id="filter-content-label">
                {t("register.country")}
              </InputLabel>
              {language === "en" ? (
                <SelectCountryEn
                  country={values.country}
                  handleChange={handleChange("country")}
                />
              ) : (
                <SelectCountryEs
                  country={values.country}
                  handleChange={handleChange("country")}
                />
              )}
            </FormControl>
            <FormControl sx={{ width: "35ch" }} variant="standard">
              <InputLabel id="filter-content-label">
                {t("register.areasOfExpertise")}
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={
                  values.areasOfExpertise?.length === 0
                    ? ["other"]
                    : values.areasOfExpertise
                }
                onChange={handleChangeAreas}
                renderValue={getRenderAreas}
              >
                {therapeuticAreaOptions.map((th) => (
                  <MenuItem key={th.id} value={th.id}>
                    <Checkbox
                      checked={
                        !!values.areasOfExpertise.find((item) => th.id === item)
                      }
                    />
                    <ListItemText
                      primary={language === "en" ? th.nameEn : th.nameEs}
                    />
                  </MenuItem>
                ))}
                <MenuItem key={"other"} value={"other"}>
                  <Checkbox
                    checked={
                      values.areasOfExpertise.length === 0 ||
                      !!values.areasOfExpertise.find((item) => "other" === item)
                    }
                  />
                  <ListItemText primary={t("other")} />
                </MenuItem>
              </Select>
              <FormHelperText>{t("register.areasAdvice")}</FormHelperText>
            </FormControl>
            <FormControl sx={{ width: "35ch" }} variant="standard">
              <InputLabel id="filter-content-label">
                {t("register.profession")}
              </InputLabel>
              <Select
                value={values.profession}
                onChange={handleChange("profession")}
              >
                {professionOptions
                  .sort((item1, item2) =>
                    item1.content.localeCompare(item2.content)
                  )
                  .map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.content}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl sx={{ width: "35ch" }} variant="standard">
              <InputLabel htmlFor="email">{t("register.email")}</InputLabel>
              <Input
                id="email"
                value={values.email}
                onChange={handleChange("email")}
              />
            </FormControl>

            <FormControl sx={{ width: "35ch", m: "0.1em" }} variant="standard">
              <InputLabel htmlFor="standard-adornment-password">
                {t("register.password")}
              </InputLabel>
              <Input
                id="standard-adornment-password"
                type={values.showPassword ? "text" : "password"}
                value={values.password}
                onChange={handleChange("password")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <FormControl sx={{ width: "35ch" }}>
                <div style={{ display: "flex", alignItems: "flex-start" }}>
                  <Checkbox
                    checked={values.isDoctor}
                    onChange={handleChangeCheckbox}
                    style={{ padding: "0 6px 0 6px" }}
                  />
                  <Typography>{t("popIn.checkboxLabel")}</Typography>
                </div>
                {showMessageMustBeDoctor && (
                  <Typography
                    style={{ color: theme.palette.servier.red }}
                    sx={{ my: 2 }}
                  >
                    {t("register.messageErrorDoctor")}
                  </Typography>
                )}
              </FormControl>
              <FormControl sx={{ width: "35ch" }} style={{ marginTop: "6px" }}>
                <div style={{ display: "flex", alignItems: "flex-start" }}>
                  <Checkbox
                    checked={values.subscribedNewsletter}
                    onChange={handleSubscribeNewsletter}
                    style={{ padding: "0 6px 0 6px" }}
                  />
                  <Typography>{t("register.subscribeNewsletter")}</Typography>
                </div>
              </FormControl>
            </div>
            {showMessageEmailAlreadyTaken && (
              <div>{t("register.emailAlreadyTaken")}</div>
            )}
            {showMessageInvalidEmail && <div>{t("register.invalidEmail")}</div>}
            <FormControl sx={{ width: "35ch" }} variant="standard">
              <Button
                disabled={
                  !(
                    values.email &&
                    values.password &&
                    values.userFirstName &&
                    values.userLastName &&
                    values.country &&
                    values.areasOfExpertise &&
                    values.profession
                  )
                }
                variant="contained"
                style={{
                  letterSpacing: "0.1em",
                  marginBottom: "1em",
                }}
                onClick={handleSubmit}
              >
                {t("register.signUp")}
              </Button>
            </FormControl>
          </Container>
        )}
      </Container>
    </Box>
  );
};

export default Register;
